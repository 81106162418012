<template>
  <div class="product-box" ref="aff">
    <h2>查询条件</h2>
    <div class="box">
      <div class="select-box">
        产品编号
        <a-input v-model:value="search.productFyId" class="mt5" placeholder="产品编号" />
      </div>
      <div class="select-box">
        产品名称
        <a-input v-model:value="search.productFyName" class="mt5" placeholder="产品名称" />
      </div>
      <div class="select-box">
        适用人群
        <a-select v-model:value="search.applyObject" class="mt5" style="width: 200px">
          <a-select-option value="">全部</a-select-option>
          <a-select-option value="不限">不限</a-select-option>
          <a-select-option value="男">男</a-select-option>
          <a-select-option value="女">女</a-select-option>
        </a-select>
      </div>
      <div class="select-box">
        是否已婚
        <a-select v-model:value="search.isMarrid" class="mt5" style="width: 200px">
          <a-select-option value="">全部</a-select-option>
          <a-select-option value="2">不限</a-select-option>
          <a-select-option value="1">未婚</a-select-option>
          <a-select-option value="0">已婚</a-select-option>
        </a-select>
      </div>
    </div>
    <div class="box">
      <div class="select-box" style="width: 440px">
        区域
        <a-cascader
          v-model:value="searchCity"
          change-on-select
          :options="options"
          :show-search="{ filter }"
          class="mt5"
          placeholder="请选择"
          style="width: 440px"
        />
      </div>
      <div class="select-box">
        产品状态
        <a-select v-model:value="search.isValid" class="mt5" style="width: 200px">
          <a-select-option value="">全部</a-select-option>
          <a-select-option :value="true">有效</a-select-option>
          <a-select-option :value="false">无效</a-select-option>
        </a-select>
      </div>
    </div>
    <div class="button-box">
      <a-button :loading="getloading" style="width: 85px" type="primary" @click="getListData"
        >查询
      </a-button>
      <a-button style="width: 85px" type="primary" @click="reset">重置</a-button>
      <a-button :loading="syncLoading" style="width: 85px" type="primary" @click="sync"
        >同步
      </a-button>
    </div>
    <a-table
      bordered
      :columns="columns"
      :dataSource="dataSource"
      class="mt30"
      :pagination="position"
      :loading="loading"
      @change="tableChange"
    >
      <template #bodyCell="{ column, text, record }">
        <template v-if="column.dataIndex === 'isMarrid'">
          {{ marry(text) }}
        </template>
        <template v-if="column.dataIndex === 'organizations'">
          <div class="cityList">
            <span v-for="(item, index) in text" :key="index">
              {{ item.province + item.city + item.area }}
            </span>
          </div>
        </template>
        <template v-if="column.dataIndex === 'isValid'">
          <span :class="{ red: !text }"> {{ text ? '有效' : '无效' }}</span>
        </template>
        <template v-if="column.dataIndex === 'action'">
          <div class="action-box">
            <a-button @click="getDetailData(record.guid, '0')">查看</a-button>
            <a-button type="primary" @click="getDetailData(record.guid, '1')">体检项目</a-button>
          </div>
        </template>
      </template>
    </a-table>
  </div>
  <a-modal
    width="80%"
    :getContainer="$refs.aff"
    v-model:visible="visible[0]"
    :footer="false"
    wrapClassName="modalStyle"
    centered
  >
    <a-descriptions title="基本信息" bordered>
      <a-descriptions-item label="产品编号">
        {{ basic.productFyId }}
      </a-descriptions-item>
      <a-descriptions-item label="泛优编号">{{ basic.guid }}</a-descriptions-item>
      <a-descriptions-item label="产品名称">
        {{ basic.productFyName }}
      </a-descriptions-item>
      <a-descriptions-item label="适用人群">{{ basic.applyObject }}</a-descriptions-item>
      <a-descriptions-item label="是否已婚">{{ marry(basic.isMarrid) }}</a-descriptions-item>
      <a-descriptions-item label="采购价格">{{ basic.sellPrice }}</a-descriptions-item>
      <a-descriptions-item label="区域">
        <div class="cityList">
          <span v-for="(item, index) in basic.organizations" :key="index">
            {{ item.province + item.city + item.area }}
          </span>
        </div>
      </a-descriptions-item>
      <a-descriptions-item label="产品获取时间">
        {{ basic.createTime }}
      </a-descriptions-item>
      <a-descriptions-item label="产品状态:">
        {{ basic.isValid === true ? '有效' : '无效' }}
      </a-descriptions-item>
      <a-descriptions-item label="备注:">{{ basic.remark }}</a-descriptions-item>
    </a-descriptions>
    <div class="title-style">体验门店</div>
    <a-table :columns="columns1" :dataSource="basic.organizations" :pagination="false" bordered>
    </a-table>
  </a-modal>
  <a-modal
    width="80%"
    :getContainer="$refs.aff"
    v-model:visible="visible[1]"
    :footer="false"
    wrapClassName="modalStyle"
    centered
  >
    <h2>体检项目详情</h2>
    <a-table :columns="columns2" :dataSource="examinationDetail" :pagination="false" bordered />
  </a-modal>
</template>

<script setup>
import { computed, reactive, ref } from 'vue';
import { pageProductFY, productFYDetail, syncData } from '@/api';
import { message, Modal } from 'ant-design-vue';
import cityData from '@/components/city.json';
//表格数据loading
const loading = ref(false);
//列表数据
const dataSource = ref([]);
//列表配置
const columns = [
  {
    title: '产品编号',
    dataIndex: 'productFyId',
    key: 'productFyId',
    align: 'center'
  },
  {
    title: '产品名称',
    dataIndex: 'productFyName',
    key: 'productFyName',
    align: 'center'
  },
  {
    //不限,男,女
    title: '适用人群',
    dataIndex: 'applyObject',
    key: 'applyObject',
    align: 'center'
  },
  {
    // 0已结婚 1未结婚 2不限 传空为全部
    title: '是否已婚',
    key: 'isMarrid',
    dataIndex: 'isMarrid',
    align: 'center'
  },
  {
    title: '区域',
    key: 'organizations',
    dataIndex: 'organizations',
    align: 'center'
  },
  {
    title: '采购价',
    key: 'sellPrice',
    dataIndex: 'sellPrice',
    align: 'center'
  },
  {
    //true有效
    title: '产品状态',
    key: 'isValid',
    dataIndex: 'isValid',
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: 'action',
    width: 200,
    align: 'center'
  }
];
//分页配置
let position = reactive({
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  showQuickJumper: true,
  pageSizeOptions: ['10', '20', '30', '100'],
  total: 0,
  showTotal: total => `共${total}条`,
  position: ['bottomLeft']
});
//区域数据
const searchCity = ref([]);
const options = cityData;
//区域搜索
const filter = (inputValue, path) => {
  return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
};

//是否结婚计算属性
const marry = computed(() => text => {
  // 0已婚 1未婚 2不限
  let status = '';
  if (text === 0) {
    status = '已婚';
  } else if (text === 1) {
    status = '未婚';
  } else if (text === 2) {
    status = '不限';
  } else {
    status = '状态有问题请联系管理员';
  }
  return status;
});

//搜索初始化数据
const formSource = () => {
  return reactive({
    pageIndex: 1,
    productFyId: '',
    productFyName: '',
    applyObject: '',
    isMarrid: '',
    isValid: '',
    province: '',
    city: '',
    area: ''
  });
};
//搜索条件
let search = formSource();
let conditionData = formSource();
//重置
const reset = () => {
  search = Object.assign(search, formSource());
  searchCity.value = [];
};
const getInquire = pagination => {
  loading.value = true;
  if (pagination) {
    conditionData.pageIndex = pagination.current;
    conditionData.pageSize = pagination.pageSize;
  } else {
    search.pageIndex = 1;
  }
  pageProductFY(conditionData)
    .then(res => {
      const data = res.data;
      dataSource.value = data.data;
      position.total = data.count;
      loading.value = false;
      if (pagination) {
        position.current = pagination.current;
        position.pageSize = pagination.pageSize;
      } else {
        position.current = 1;
      }
    })
    .catch(error => {
      loading.value = false;
      Modal.error({
        content: error.response.data
      });
    });
};
let getloading = ref(false);
//点击查询
const getListData = () => {
  getloading.value = true;
  conditionData = Object.assign(conditionData, search);
  conditionData.pageSize = position.pageSize;
  conditionData.province = searchCity.value[0];
  conditionData.city = searchCity.value[1];
  conditionData.area = searchCity.value[2];
  getInquire();
  getloading.value = false;
};
getListData();
//分页事件
const tableChange = pagination => {
  getInquire(pagination);
};
//查看体检门店配置
const columns1 = [
  {
    title: '门店',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '省',
    dataIndex: 'province',
    key: 'province'
  },
  {
    title: '市',
    dataIndex: 'city',
    key: 'city'
  },
  {
    title: '区',
    dataIndex: 'area',
    key: 'area'
  },
  {
    title: '地址',
    dataIndex: 'address',
    key: 'address'
  },
  {
    title: '营业时间',
    dataIndex: 'businessTime',
    key: 'businessTime'
  }
];
//体检项目配置
const columns2 = [
  {
    title: '项目种类',
    dataIndex: 'Name',
    key: 'Name'
  },
  {
    title: '检查项目',
    dataIndex: 'Type',
    key: 'Type'
  },
  {
    title: '检查意义',
    dataIndex: 'CheckRange',
    key: 'CheckRange'
  }
];
//modal[查看, 体检项目]
const visible = ref([false, false]);
//查看数据
const basic = ref({});
//体检项目modal
const examinationDetail = ref([]);
//查看体检详情
const getDetailData = (guid, num) => {
  productFYDetail(guid)
    .then(res => {
      basic.value = res.data;
      examinationDetail.value = JSON.parse(res.data.examinationDetail);
      visible.value[num] = true;
    })
    .catch(error =>
      Modal.error({
        content: error.response.data
      })
    );
};
let syncLoading = ref(false);
const sync = () => {
  loading.value = true;
  syncLoading.value = true;
  syncData()
    .then(() => {
      reset();
      getListData();
      syncLoading.value = false;
      message.success('同步成功');
    })
    .catch(error => {
      loading.value = false;
      syncLoading.value = false;
      Modal.error({
        content: error.response.data
      });
    });
};
</script>

<style scoped>
.box {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}

.select-box {
  width: 200px;
  margin-right: 40px;
  margin-top: 10px;
}

.mt5 {
  margin-top: 5px;
}

.mt30 {
  margin-top: 30px;
}

.button-box {
  width: 280px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}

.title-style {
  margin: 20px 0px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5715;
}

.action-box {
  display: flex;
  justify-content: space-between;
}

.cityList {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.red {
  color: red;
}

:deep(.ant-table-tbody) .ant-table-cell {
  padding: 4px 16px !important;
}
</style>
